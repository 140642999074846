import React from "react";
import { NavLink, Route, HashRouter as Router, Switch } from "react-router-dom";
import About from "./components/About.jsx";
import AdminAssignedCourse from "./components/AdminAssignedCourse.jsx";
import AdminPermissions from "./components/AdminPermissions.jsx";
import AdministratorAssignedStudents from "./components/AdministratorAssignedStudents.jsx";
import AdministratorCreateCourse from "./components/AdministratorCreateCourse.jsx";
import AdministratorDashboard from "./components/AdministratorDashboard.jsx";
import AdministratorManageStudents from "./components/AdministratorManageStudents.jsx";
import AdministratorReviews from "./components/AdministratorReviews.jsx";
import Blog from "./components/Blog.jsx";
import Contact from "./components/Contact.jsx";
import CreateCourse from "./components/CreateCourse.jsx";
import CreateExam from "./components/CreateExam.jsx";
import Edit from "./components/Edit.jsx";
import EditStudentProfile from "./components/EditStudentProfile.jsx";
import Exam from "./components/Exam.jsx";
import ForgotPassword from "./components/ForgotPassword.jsx";
import GradePeers from "./components/GradePeers.jsx";
import InstructorDashboard from "./components/InstructorDashboard.jsx";
import ProgramCoordinatorFeedback from "./components/ProgramCoordinatorFeedback.jsx";
import ProgramCoordinatorManageInstructors from "./components/ProgramCoordinatorManageInstructors.jsx";
import ProgramCoordinatorManageStudents from "./components/ProgramCoordinatorManageStudents.jsx";
import ProgramCoordinatorReports from "./components/ProgramCoordinatorReports.jsx";
import ProgramCoordinatorDashboard from "./components/ProgramCoordinatotDashboard.jsx";
import QAOfficerManageCourses from "./components/QAOfficerManageCourses.jsx";
import QAOfficerManageOfficers from "./components/QAOfficerManageOfficers.jsx";
import QaOfficerAssessmentTools from "./components/QaOfficerAssessmentTools.jsx";
import QaOfficerFacultyDevelopment from "./components/QaOfficerFacultyDevelopment.jsx";
import QaOfficerFeedback from "./components/QaOfficerFeedback.jsx";
import QaOfficerImprovement from "./components/QaOfficerImprovement.jsx";
import QaOfficerProgramReview from "./components/QaOfficerProgramReview.jsx";
import QaOfficerQualityStandards from "./components/QaOfficerQualityStandards.jsx";
import QaOfficerReports from "./components/QaOfficerReports.jsx";
import QaOfficerStudentSupport from "./components/QaOfficerStudentSupport.jsx";
import QaofficerDashboard from "./components/QaofficerDashboard.jsx";
import Services from "./components/Services.jsx";
import SignIn from "./components/SignIn.jsx";
import SignUp from "./components/SignUp.jsx";
import StudentDashboard from "./components/StudentDashboard.jsx";
import TakeAssessment from "./components/TakeAssessment.jsx";
import Users from "./components/Users.jsx";
import ViewAnalytics from "./components/ViewAnalytics.jsx";
import ViewReports from "./components/ViewReports.jsx";
import Home from "./components/index.jsx";
import "./style.css"; // Import the CSS file

import ManageCourses from "./components/ManageCourse";
import ManageInstructors from "./components/ManageInstructors";
import ManageQA from "./components/ManageQA";
import ManageStudents from "./components/ManageStudents";

import AddCourse from "./components/AddCourse.jsx";
import AddInstructor from "./components/AddInstructor.jsx";
import AddStudent from "./components/AddStudent.jsx";
import AddQA from "./components/Addqa.jsx";
import Analytics from "./components/Analytics";
import AssignedStudents from "./components/AssignedStudents";
import EditCourse from "./components/EditCourse.jsx";
import EditInstructor from "./components/EditInstructor.jsx";
import EditStudent from "./components/EditStudent.jsx";
import EditQA from "./components/Editqa.jsx";
import Header from "./components/Header";
import Performance from "./components/Performance";
import Permissions from "./components/Permissions";
import PrivateRoute from "./components/PrivateRoute.jsx";
import ReportQA from "./components/ReportQA";
import ReportStudent from "./components/ReportStudent";
import Report_S from "./components/Report_S";

function App() {
  const currentRoute = window.location.hash.substring(1);
  console.log("Current Route:", currentRoute);
  return (
    <Router>
      <div>
        {/* Header Section */}
        <header>
          <div className="navbar">
            <div className="logo">
              <h2>MSC Educational Management</h2>
            </div>
            <nav id="navbar">
              <ul>
                <li>
                  <NavLink exact to="/">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/Blog">Blog</NavLink>
                </li>
                <li>
                  <NavLink to="/About">About Us</NavLink>
                </li>
                <li>
                  <NavLink to="/Services">Services</NavLink>
                </li>
                <li>
                  <NavLink to="/SignIn">Login</NavLink>
                </li>
                <li>
                  <NavLink to="/Contact">Contact Us</NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <Switch>
          <PrivateRoute exact path="/">
            <Home />
          </PrivateRoute>
          <PrivateRoute path="/About">
            <About />
          </PrivateRoute>
          <PrivateRoute path="/Services">
            <Services />
          </PrivateRoute>
          <PrivateRoute path="/SignIn">
            <SignIn />
          </PrivateRoute>
          <PrivateRoute path="/Contact">
            <Contact />
          </PrivateRoute>
          <PrivateRoute path="/SignUp">
            <SignUp />
          </PrivateRoute>
          <PrivateRoute path="/Blog">
            <Blog />
          </PrivateRoute>
          <PrivateRoute exact path="/manageinstructors">
            <ManageInstructors />
          </PrivateRoute>
          {/* Add a route for the "ManageInstructors" component */}
          <PrivateRoute exact path="/managestudents">
            <ManageStudents />
          </PrivateRoute>
          {/* Add a route for the "ManageStudents" component */}
          <PrivateRoute exact path="/managecourses">
            <ManageCourses />
          </PrivateRoute>
          {/* Add a route for the "ManageCourses" component */}
          <PrivateRoute exact path="/manageqa">
            <ManageQA />
          </PrivateRoute>
          {/* Add a route for the "ManageQA" component */}
          <PrivateRoute path="/Report_S">
            <Report_S />
          </PrivateRoute>
          <PrivateRoute path="/Analytics">
            <Analytics />
          </PrivateRoute>
          <PrivateRoute path="/AssignedStudents">
            <AssignedStudents />
          </PrivateRoute>
          <PrivateRoute path="/Permissions">
            <Permissions />
          </PrivateRoute>
          <PrivateRoute path="/ReportQA">
            <ReportQA />
          </PrivateRoute>
          <PrivateRoute path="/ReportStudent">
            <ReportStudent />
          </PrivateRoute>
          <PrivateRoute path="/Performance">
            <Performance />
          </PrivateRoute>
          <PrivateRoute exact path="/addinstructor">
            <AddInstructor />
          </PrivateRoute>
          {/* Add a route for the "AddInstructor" component */}
          <PrivateRoute exact path="/editinstructor">
            <EditInstructor />
          </PrivateRoute>
          {/* Add a route for the "EditInstructor" component */}
          <PrivateRoute exact path="/addstudent">
            <AddStudent />
          </PrivateRoute>
          {/* Add a route for the "AddStudent" component */}
          <PrivateRoute exact path="/editstudent">
            <EditStudent />
          </PrivateRoute>
          {/* Add a route for the "EditStudent" component */}
          <PrivateRoute exact path="/addqa">
            <AddQA />
          </PrivateRoute>
          {/* Add a route for the "AddQA" component */}
          <PrivateRoute exact path="/editqa">
            <EditQA />
          </PrivateRoute>
          {/* Add a route for the "EditQA" component */}
          <PrivateRoute exact path="/addcourse">
            <AddCourse />
          </PrivateRoute>
          {/* Add a route for the "AddCourse" component */}
          <PrivateRoute exact path="/editcourse">
            <EditCourse />
          </PrivateRoute>
          {/* Add a route for the "EditCourse" component */}
          {/* Add more routes for other pages as needed */}
          <PrivateRoute path="/QAOfficerManageCourses">
            <QAOfficerManageCourses />
          </PrivateRoute>
          <PrivateRoute path="/QAOfficerManageOfficers">
            <QAOfficerManageOfficers />
          </PrivateRoute>
          <PrivateRoute path="/AdministratorManageStudents">
            <AdministratorManageStudents />
          </PrivateRoute>
          <PrivateRoute path="/AdministratorReviews">
            <AdministratorReviews />
          </PrivateRoute>
          <PrivateRoute path="/AdminPermissions">
            <AdminPermissions />
          </PrivateRoute>
          <PrivateRoute path="/AdminAssignedCourse">
            <AdminAssignedCourse />
          </PrivateRoute>
          <PrivateRoute path="/AdministratorAssignedStudents">
            <AdministratorAssignedStudents />
          </PrivateRoute>
          <PrivateRoute path="/AdministratorCreateCourse">
            <AdministratorCreateCourse />
          </PrivateRoute>
          <PrivateRoute path="/AdministratorDashboard">
            <AdministratorDashboard />
          </PrivateRoute>
          <PrivateRoute path="/CreateCourse">
            <CreateCourse />
          </PrivateRoute>
          <PrivateRoute path="/CreateExam">
            <CreateExam />
          </PrivateRoute>
          <PrivateRoute path="/Edit">
            <Edit />
          </PrivateRoute>
          <PrivateRoute path="/EditStudentProfile">
            <EditStudentProfile />
          </PrivateRoute>
          <PrivateRoute path="/Exam">
            <Exam />
          </PrivateRoute>
          <PrivateRoute path="/ForgotPassword">
            <ForgotPassword />
          </PrivateRoute>
          <PrivateRoute path="/GradePeers">
            <GradePeers />
          </PrivateRoute>
          <PrivateRoute path="/InstructorDashboard">
            <InstructorDashboard />
          </PrivateRoute>
          <PrivateRoute path="/StudentDashboard">
            <StudentDashboard />
          </PrivateRoute>
          <PrivateRoute path="/ProgramCoordinatorDashboard">
            <ProgramCoordinatorDashboard />
          </PrivateRoute>
          <PrivateRoute path="/ProgramCoordinatorFeedback">
            <ProgramCoordinatorFeedback />
          </PrivateRoute>
          <PrivateRoute exact path="/manageinstructors">
            <ManageInstructors />
          </PrivateRoute>
          {/* Add a route for the "ManageInstructors" component */}
          <PrivateRoute exact path="/managestudents">
            <ManageStudents />
          </PrivateRoute>
          {/* Add a route for the "ManageStudents" component */}
          <PrivateRoute exact path="/managecourses">
            <ManageCourses />
          </PrivateRoute>
          {/* Add a route for the "ManageCourses" component */}
          <PrivateRoute exact path="/manageqa">
            <ManageQA />
          </PrivateRoute>
          {/* Add a route for the "ManageQA" component */}
          <PrivateRoute exact path="/addinstructor">
            <AddInstructor />
          </PrivateRoute>
          {/* Add a route for the "AddInstructor" component */}
          <PrivateRoute exact path="/editinstructor">
            <EditInstructor />
          </PrivateRoute>
          {/* Add a route for the "EditInstructor" component */}
          <PrivateRoute exact path="/addstudent">
            <AddStudent />
          </PrivateRoute>
          {/* Add a route for the "AddStudent" component */}
          <PrivateRoute exact path="/editstudent">
            <EditStudent />
          </PrivateRoute>
          {/* Add a route for the "EditStudent" component */}
          <PrivateRoute exact path="/addqa">
            <AddQA />
          </PrivateRoute>
          {/* Add a route for the "AddQA" component */}
          <PrivateRoute exact path="/editqa">
            <EditQA />
          </PrivateRoute>
          {/* Add a route for the "EditQA" component */}
          <PrivateRoute exact path="/addcourse">
            <AddCourse />
          </PrivateRoute>
          {/* Add a route for the "AddCourse" component */}
          <PrivateRoute exact path="/editcourse">
            <EditCourse />
          </PrivateRoute>
          {/* Add a route for the "EditCourse" component */}
          {/* Add more routes for other pages as needed */}
          <PrivateRoute path="/ProgramCoordinatorReports">
            <ProgramCoordinatorReports />
          </PrivateRoute>
          <PrivateRoute path="/ProgramCoordinatorManageInstructors">
            <ProgramCoordinatorManageInstructors />
          </PrivateRoute>
          <PrivateRoute path="/ProgramCoordinatorManageStudents">
            <ProgramCoordinatorManageStudents />
          </PrivateRoute>
          <PrivateRoute path="/QaOfficerAssessmentTools">
            <QaOfficerAssessmentTools />
          </PrivateRoute>
          <PrivateRoute path="/QaOfficerFacultyDevelopment">
            <QaOfficerFacultyDevelopment />
          </PrivateRoute>
          <PrivateRoute path="/QaOfficerReports">
            <QaOfficerReports />
          </PrivateRoute>
          <PrivateRoute path="/QaOfficerQualityStandards">
            <QaOfficerQualityStandards />
          </PrivateRoute>
          <PrivateRoute path="/QaOfficerProgramReview">
            <QaOfficerProgramReview />
          </PrivateRoute>
          <PrivateRoute path="/QaOfficerFeedback">
            <QaOfficerFeedback />
          </PrivateRoute>
          <PrivateRoute path="/QaOfficerImprovement">
            <QaOfficerImprovement />
          </PrivateRoute>
          <PrivateRoute path="/QaofficerDashboard">
            <QaofficerDashboard />
          </PrivateRoute>
          <PrivateRoute path="/QaOfficerStudentSupport">
            <QaOfficerStudentSupport />
          </PrivateRoute>
          <PrivateRoute path="/TakeAssessment">
            <TakeAssessment />
          </PrivateRoute>
          <PrivateRoute path="/Users">
            <Users />
          </PrivateRoute>
          <PrivateRoute path="/ViewAnalytics">
            <ViewAnalytics />
          </PrivateRoute>
          <PrivateRoute path="/Header">
            <Header />
          </PrivateRoute>
          <PrivateRoute path="/ViewReports">
            <ViewReports />
          </PrivateRoute>
        </Switch>

        {/* Footer Section */}
        <footer>
          <p>&copy; 2023 Msc Educational Management</p>
        </footer>
      </div>
    </Router>
  );
}

export default App;
