import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import NotFound from "./NotFound";

function PrivateRoute({ children }) {
  const userRole = localStorage.getItem("role");
  const history = useHistory();

  const studentsRoutes = [
    "About",
    "Services",
    "SignIn",
    "Contact",
    "SignUp",
    "Blog",
    "ReportStudent",
    "Performance",
    "EditStudentProfile",
    "StudentDashboard",
    "ViewAnalytics",
    "TakeAssessment",
  ];
  const adminRoutes = [
    "About",
    "Services",
    "SignIn",
    "Contact",
    "SignUp",
    "Blog",
    "addstudent",
    "editstudent",
    "addqa",
    "editqa",
    "addcourse",
    "editcourse",
    "AdministratorManageStudents",
    "AdministratorReviews",
    "AdminPermissions",
    "AdminAssignedCourse",
    "AdministratorAssignedStudents",
    "AdministratorCreateCourse",
    "AdministratorDashboard",
    "CreateCourse",
    "CreateExam",
    "manageinstructors",
    "managestudents",
    "managecourses",
    "manageqa",
    "addinstructor",
    "editinstructor",
    "addstudent",
    "addqa",
    "addcourse",
    "editcourse",
    "ReportStudent",
    "ViewAnalytics",
    "AssignedStudents",
  ];
  const pcRoutes = [
    "About",
    "Services",
    "SignIn",
    "Contact",
    "SignUp",
    "Blog",
    "addinstructor",
    "editinstructor",
    "ProgramCoordinatorDashboard",
    "ProgramCoordinatorReports",
    "ProgramCoordinatorManageInstructors",
    "ProgramCoordinatorManageStudents",
    "ProgramCoordinatorFeedback",
  ];
  const instructorRoutes = [
    "About",
    "Services",
    "SignIn",
    "Contact",
    "SignUp",
    "Blog",
    "managecourses",
    "editinstructor",
    "GradePeers",
    "InstructorDashboard",
    "Edit",
    "Exam",
  ];
  const qaRoutes = [
    "About",
    "Services",
    "SignIn",
    "Contact",
    "SignUp",
    "Blog",
    "QAOfficerManageCourses",
    "QAOfficerManageOfficers",
    "QaOfficerAssessmentTools",
    "QaOfficerFacultyDevelopment",
    "QaOfficerQualityStandards",
    "QaOfficerProgramReview",
    "QaOfficerFeedback",
    "QaOfficerImprovement",
    "QaofficerDashboard",
    "QaOfficerStudentSuppor",
  ];
  const anything = [
    "/",
    "Signup",
    "About",
    "Services",
    "SignIn",
    "Contact",
    "signin",
    "Blog",
    "ForgotPassword",
  ];

  const currentRoute =
    window.location.hash.substring(1) === "/"
      ? window.location.hash.substring(1)
      : window.location.hash.substring(2);
  console.log("Current Route:", currentRoute);

  let allowedRoutes = [];

  // Assign the correct routes based on the userRole
  switch (userRole) {
    case "student":
      allowedRoutes = studentsRoutes;
      break;
    case "admin":
      allowedRoutes = adminRoutes;
      break;
    case "qa":
      allowedRoutes = qaRoutes;
      break;
    case "instructor":
      allowedRoutes = instructorRoutes;
      break;
    case "pc":
      allowedRoutes = pcRoutes;
      break;
    default:
      allowedRoutes = anything;
  }
  return allowedRoutes.includes(currentRoute) ||
    anything.includes(currentRoute) ? (
    children
  ) : (
    <NotFound />
  );
}
export default PrivateRoute;
