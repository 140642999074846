import React, { useState, useEffect } from "react";
import AddCourse from "./AddCourse";
import EditCourse from "./EditCourse";

function ManageCourses() {
  const [courses, setCourses] = useState([]);
  

  useEffect(() => {
    // Fetch the list of Courses to populate the list
    fetch(`${process.env.REACT_APP_API_BASE_URL}/retrieveCourses`)
      .then((response) => response.json())
      .then((data) => {
        setCourses(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  return (
    <div>
      <center>
        <h1>Manage Courses</h1>
      </center>

      <AddCourse
        refreshCourses={() => {
          // Implement a function to refresh the Courses list after adding or editing
          fetch(`${process.env.REACT_APP_API_BASE_URL}/index`)
            .then((response) => response.json())
            .then((data) => {
              setCourses(data);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }}
      />

      <EditCourse
        courses={courses}
        refreshCourses={() => {
          // Implement a function to refresh the Courses list after adding or editing
          fetch(`${process.env.REACT_APP_API_BASE_URL}/index`)
            .then((response) => response.json())
            .then((data) => {
              setCourses(data);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }}
      />

      <ul>
        {courses ? (
          courses.map((course) => (
            <li key={course.id}>
              {course.name} - {course.email} - {course.course}
            </li>
          ))
        ) : (
          <li>No courses available</li>
        )}
      </ul>
    </div>
  );
}

export default ManageCourses;
