import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <h1 style={{ fontSize: "4rem", marginBottom: "10px" }}>404 - Not Found</h1>
      <p style={{ fontSize: "1.2rem", color: "#555" }}>
        The page you are looking for does not exist.
      </p>
      <Link to="/" style={{ marginTop: "20px", textDecoration: "none" }}>
        <button
          style={{
            padding: "10px 20px",
            fontSize: "1rem",
            backgroundColor: "#007BFF",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Go to Home
        </button>
      </Link>
    </div>
  );
};

export default NotFound;
