import React, { useState } from "react";
import "./style.css";
import { Link, useHistory } from "react-router-dom";
import loginImage from "./login.jpg";

function SignIn() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const history = useHistory();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!username || !password) {
      setError("Please fill in both username and password fields.");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/signIn`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, password }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      if (data.status === 0) {
        // Redirect to a page based on the user's role
        window.localStorage.setItem("role", data.user.role);
        window.localStorage.setItem("userid", data.user.id);
        switch (data.user.role) {
          case "student":
            history.push("/StudentDashboard");
            break;
          case "admin":
            history.push("/AdministratorDashboard");
            break;
          case "qa":
            history.push("/QaOfficerDashboard");
            break;
          case "instructor":
            history.push("/InstructorDashboard");
            break;
          case "pc":
            history.push("/ProgramCoordinatorDashboard");
            break;
          default:
            history.push("/signin");
        }
      } else {
        setError(data.message);
      }
    } catch (error) {
      setError("An error occurred: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="content">
        <img
          src={loginImage}
          alt="Image Description"
          style={{ width: "100px", height: "100px" }}
          className="login-image"
        />

        <div className="container">
          <center>
            <h2>Sign In</h2>
          </center>
          <br />
          {error && <div className="error-message">{error}</div>}
          <form onSubmit={handleFormSubmit} method="post">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              id="username"
              name="username"
              required
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />

            <label htmlFor="password">Password:</label>
            <input
              type="password"
              id="password"
              name="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <p className="forgot-password-link">
              <Link to="/ForgotPassword">Forgot Password?</Link>
            </p>
            <br></br>
            <button className="login-button" type="submit" disabled={loading}>
              {loading ? "Logging In..." : "LOGIN"}
            </button>
          </form>

          <br></br>
          <p>If you don't have an account, sign up here</p>
          <Link to="/Signup">
            <button className="login-button">SIGN UP</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
