import React, { useState, useEffect } from 'react';
import AddStudent from './AddStudent';
import EditStudent from './EditStudent';

function ManageStudents() {
  const [students, setStudents] = useState([]);


  useEffect(() => {
    // Fetch the list of Students to populate the list
    fetch(`${process.env.REACT_APP_API_BASE_URL}/retrieveStudents`)
      .then((response) => response.json())
      .then((data) => {
        setStudents(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  return (
    <div>
      <center><h1>Manage Students</h1></center>

      <AddStudent refreshStudents={() => {
        // Implement a function to refresh the Students list after adding or editing
        fetch(`${process.env.REACT_APP_API_BASE_URL}/retrieveStudents`)
          .then((response) => response.json())
          .then((data) => {
            setStudents(data);
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }} />

      <EditStudent students={students} refreshStudents={() => {
        // Implement a function to refresh the Students list after adding or editing
        fetch(`${process.env.REACT_APP_API_BASE_URL}/retrieveStudents`)
          .then((response) => response.json())
          .then((data) => {
            setStudents(data);
          })
          .catch((error) => {
            console.error('Error:', error);
          });
      }} />

      {/* Render the list of Students */}
      <ul>
  {students ? (
    students.map((student) => (
      <li key={student.id}>
        {student.name} - {student.email} - {student.student}
      </li>
    ))
  ) : (
    <li>No students available</li>
  )}
</ul>

    </div>
  );
}

export default ManageStudents;
