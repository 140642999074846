import React, { useState } from "react";
import "./style.css"; // Import your CSS file
import signupImage from "./signup.jpg"; // Import your image
import { Link } from "react-router-dom";

function Signup() {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    role: "student",
    fullName: "",
    birthdate: "",
  });

  const [passwordError, setPasswordError] = useState("");
  const [registrationStatus, setRegistrationStatus] = useState("");
  const [verifyOtp, setverifyOtp] = useState(false);
  const [otp, setotp] = useState("");
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (verifyOtp) {
      const otpData = {
        email: formData.email,
        otp: otp,
      };
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/otpVerify`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(otpData),
        });
        if (response.ok) {
          const data = await response.json();
          if (data.message === "OTP Verified successfully") {
            alert("OTP Verified successfully\n Registration Successful");
            window.location.reload();
          } else {
            setRegistrationStatus(data.message);
          }
        } else {
          setRegistrationStatus(`Unable to verify OTP. Please Contact Admin`);
        }
      } catch (error) {
        setRegistrationStatus("An error occurred. Please try again later.");
      }
    } else {
      if (formData.password !== formData.confirmPassword) {
        setPasswordError("Passwords do not match");
        return;
      }

      // Password validation
      const passwordValidations = validatePassword(formData.password);
      if (passwordValidations.length > 0) {
        setPasswordError(passwordValidations.join("\n"));
        return;
      }

      // Reset password error if it was previously set
      setPasswordError("");

      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/signup`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
        const formatErrors = (errors) => {
          return Object.entries(errors)
            .map(
              ([field, messages]) =>
                `${
                  field.charAt(0).toUpperCase() + field.slice(1)
                }: ${messages.join(", ")}`
            )
            .join(", ");
        };
        if (response.ok) {
          const data = await response.json();
          if (data.message === "OTP sent successfully") {
            setverifyOtp(true);
          } else {
            setRegistrationStatus(data.message);
          }
        } else {
          const errorData = await response.json();
          const formattedErrors = formatErrors(errorData.errors);
          setRegistrationStatus(`Error registering: ${formattedErrors}`);
        }
      } catch (error) {
        setRegistrationStatus("An error occurred. Please try again later.");
      }
    }
  };

  const validatePassword = (password) => {
    const validations = [];

    if (password.length < 8) {
      validations.push("Password must be at least 8 characters long.");
    }
    if (!/[A-Z]/.test(password)) {
      validations.push("Password must contain an uppercase letter.");
    }
    if (!/[a-z]/.test(password)) {
      validations.push("Password must contain a lowercase letter.");
    }
    if (!/\d/.test(password)) {
      validations.push("Password must contain a number.");
    }
    if (!/[$&+,:;=?@#|'<>.^*()%!-]/.test(password)) {
      validations.push(
        "Password must contain a special character ($&+,:;=?@#|'<>.^*()%!-)."
      );
    }

    return validations;
  };

  return (
    <div>
      <div className="container">
        <div className="content">
          <div className="image">
            <img src={signupImage} alt="Image Description" />
          </div>
          <div className="container">
            <center>
              <h2>Sign Up</h2>
            </center>
            <br />
            <div>
              <p>
                If you already have an account,{" "}
                <Link to="/signin">login here</Link>
              </p>
              <br></br>
            </div>
            <form onSubmit={handleFormSubmit} method="post">
              <label htmlFor="username">Username:</label>
              <input
                type="text"
                id="username"
                name="username"
                required
                value={formData.username}
                onChange={handleInputChange}
              />
              <br></br>
              <br></br>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                required
                value={formData.email}
                onChange={handleInputChange}
              />
              <br></br>
              <br></br>
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                required
                value={formData.password}
                onChange={handleInputChange}
              />
              <br></br>
              <br></br>
              <label htmlFor="confirmPassword">Confirm Password:</label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                required
                value={formData.confirmPassword}
                onChange={handleInputChange}
              />
              <br></br>
              <br></br>
              <div className="form-group">
                <label htmlFor="role">Select Role</label>
                <select
                  id="role"
                  name="role"
                  value={formData.role}
                  onChange={handleInputChange}
                >
                  <option value="student">Student</option>
                  <option value="admin">Admin</option>
                  <option value="qa">QA Officer</option>
                  <option value="instructor">Instructor</option>
                  <option value="pc">Program Coordinator</option>
                </select>
              </div>

              <label htmlFor="fullName">Full Name:</label>
              <input
                type="text"
                id="fullName"
                name="fullName"
                required
                value={formData.fullName}
                onChange={handleInputChange}
              />
              <br></br>
              <br></br>
              <label htmlFor="birthdate">Birthdate:</label>
              <input
                type="date"
                id="birthdate"
                name="birthdate"
                required
                value={formData.birthdate}
                onChange={handleInputChange}
              />
              <br></br>
              <br></br>

              {verifyOtp && (
                <>
                  <label htmlFor="otp">OTP: </label>
                  <input
                    type="text"
                    id="otp"
                    name="otp"
                    required
                    value={otp}
                    onChange={(e) => {
                      setotp(e.target.value);
                    }}
                  />
                </>
              )}
              <br></br>
              <br></br>
              {passwordError && (
                <p className="error-message">{passwordError}</p>
              )}
              {registrationStatus && (
                <p className="registration-status">{registrationStatus}</p>
              )}
              <br></br>
              <br></br>
              <button className="login-button" type="submit">
                {verifyOtp ? "Verify OTP" : "Register"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Signup;
