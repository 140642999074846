import React, { useEffect, useState } from "react";
import Addqa from "./Addqa";
import Editqa from "./Editqa";

function Manageqa() {
  const [qa, setqa] = useState([]);

  useEffect(() => {
    // Fetch the list of qa to populate the list
    fetch(`${process.env.REACT_APP_API_BASE_URL}/retrieveQas`)
      .then((response) => response.json())
      .then((data) => {
        setqa(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const listStyle = {
    listStyle: "none",
    padding: "10px",
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    width: "fit-content",
  };

  const listItemStyle = {
    marginBottom: "8px",
    padding: "8px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    width: "fit-content",
  };

  return (
    <div>
      <center>
        <h1>Manage qa</h1>
      </center>
      <Addqa
        refreshqa={() => {
          // Implement a function to refresh the qa list after adding or editing
          fetch(`${process.env.REACT_APP_API_BASE_URL}/retrieveQas`)
            .then((response) => response.json())
            .then((data) => {
              setqa(data);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }}
      />
      <Editqa
        qas={qa}
        refreshqas={() => {
          // Implement a function to refresh the instructors list after adding or editing
          fetch(`${process.env.REACT_APP_API_BASE_URL}/retrieveQas`)
            .then((response) => response.json())
            .then((data) => {
              setqa(data);
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }}
      />
      <center>
        <h1>Available QAs</h1>
        <ul style={listStyle}>
          {qa ? (
            qa.map((qa) => (
              <li key={qa.id} style={listItemStyle}>
                {qa.name} - {qa.email} - {qa.qa}
              </li>
            ))
          ) : (
            <li style={listItemStyle}>No QA available</li>
          )}
        </ul>
      </center>{" "}
    </div>
  );
}

export default Manageqa;
